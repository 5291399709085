<template>
	<div class="lump" :style="lumpBg">
		<div @click="toRanking" class="activity-btn" :style="roundBtn">排行说明</div>
		<p class="num-box">当前排名：<span>{{rank != 0 ? rank : '- -'}}</span></p>
		<div class="list-box">
			<img class="top-bj" src="@/assets/images/newyear/ranklist-bj.png" alt="">
			<div v-if="rankList.length > 0" class="top-three">
				<div class="two">
					<img class="head-pic" :src="imgUrl+rankList[1].avatar" :onerror="errorImage">
					<img class="ring-no" src="@/assets/images/newyear/ring-2.png" alt="">
					<p class="name">{{rankList[1].nickName}}</p>
					<p class="num">{{rankList[1].sumIntegral}}积分</p>
				</div>
				<div class="one">
					<img class="head-pic" :src="imgUrl+rankList[0].avatar" :onerror="errorImage">
					<img class="ring-no" src="@/assets/images/newyear/ring-1.png" alt="">
					<p class="name">{{rankList[0].nickName}}</p>
					<p class="num">{{rankList[0].sumIntegral}}积分</p>
				</div>
				<div class="three">
					<img class="head-pic" :src="imgUrl+rankList[2].avatar" :onerror="errorImage">
					<img class="ring-no" src="@/assets/images/newyear/ring-3.png" alt="">
					<p class="name">{{rankList[2].nickName}}</p>
					<p class="num">{{rankList[2].sumIntegral}}积分</p>
				</div>
			</div>
			<ul v-if="rankList.length > 0" class="list">
				<li v-for="(item, index) in rankList" :key="index">
					<div class="item" v-if="index > 2 && index < 10">
						<div class="name-box">
							<div class="pic">
								<img :src="imgUrl+item.avatar" :onerror="errorImage">
								<p>NO.{{index + 1}}</p>
							</div>
							<!-- <p class="name">名字<span style="background: #64A6F8;">30岁</span></p> -->
							<p class="name">{{item.nickName}}</p>
						</div>
						<p class="num">{{item.sumIntegral}}积分</p>
					</div>
				</li>
			</ul>
		</div>
		<div v-if="loading" class="loading">
			<van-loading color="#fd4749" size="40" />
		</div>
	</div>
</template>

<script>
	import { getRankListApi } from '@/request/api'
	export default {
		data() {
			return {
				lumpBg: {
					background: `#fd4749 url(${require("@/assets/images/newyear/ranking-bj.png")})`,
					backgroundSize: '100% auto',
					backgroundRepeat: 'no-repeat'
				},
				roundBtn: {
					background: `url(${require("@/assets/images/newyear/round-btn.png")})`,
					backgroundSize: '100% 100%',
				},
				errorImage: 'this.src="' + require('@/assets/images/newyear/default-pic.png') + '"',
				rank: 0,
				rankList: [],
				loading: false,
				imgUrl: ''
			}
		},
		methods: {
			getRankList() {
				this.loading = true
				getRankListApi().then(res => {
					this.loading = false
					this.rankList = res.data
					console.log(this.rankList)
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			toRanking() {
				this.$router.push({
					path: '/rankingExplain'
				})
			}
		},
		mounted() {
			this.imgUrl = process.env.VUE_APP_IMG_URL
			this.rank = this.$route.query.rank
			this.getRankList()
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		position relative
		padding 4.5rem 0 0.38rem
		min-height 100vh
		box-sizing border-box
		.loading 
			position absolute
			top 0
			right 0
			bottom 0
			left 0
			z-index 10
			display flex
			justify-content center
			align-items center
			background rgba(255, 255, 255, 0.8)
		.activity-btn
			position absolute
			right 0.4rem
			top 0.426666rem
			display flex
			justify-content center
			align-items center
			width 1.9rem
			height 0.6rem
			font-size 0.346666rem
			color #CE2625
		.num-box
			margin-left 0.64rem
			text-align left
			color #FFFFFF
			font-size 0.373333rem
			letter-spacing 1px
			span 
				color #FCDB77
		.list-box
			position relative
			margin 0.42rem 0.373333rem 0
			background #FFFFFF
			box-shadow 0px 8px 19px 1px rgba(194, 23, 26, 0.73)
			border-radius 0.28rem
			text-align left
			.top-bj
				position absolute
				width 100%
				left 0
				top -0.4rem
			.top-three
				position relative
				z-index 2
				display flex
				justify-content center
				align-items center
				div
					position relative
					box-sizing border-box
					text-align center
					.num
						font-size 0.32rem
				.one 
					width 36%
					padding 0.426666rem 0.48rem 0
					.ring-no
						width 100%
						height auto
					.head-pic
						position absolute
						top 0.96rem
						left 0.933333rem
						width 1.493333rem
						height 1.493333rem
						border-radius 50%
						z-index -1
					p
						font-weight bold
						color #E8403E
					.name
						margin 0.426666rem 0 0.213333rem
						font-size 0.48rem
				.two
					width 32%
					padding 0.8rem 0.506666rem 0 0.426666rem
					.ring-no
						width 100%
						height auto
					.head-pic
						position absolute
						top 1.2rem
						left 0.826666rem
						width 1.173333rem
						height 1.173333rem
						border-radius 50%
						z-index -1
					p
						font-weight bold
						color #FEA028
					.name
						margin 0.266666rem 0 0.213333rem
						font-size 0.4rem
				.three
					width 32%
					padding 0.8rem 0.426666rem 0 0.506666rem
					.ring-no
						width 100%
						height auto
					.head-pic
						position absolute
						top 1.2rem
						left 0.933333rem
						width 1.173333rem
						height 1.173333rem
						border-radius 50%
						z-index -1
					p
						font-weight bold
						color #D64FBB
					.name
						margin 0.213333rem 0
						font-size 0.4rem
			.list
				padding 1.066666rem 0.266666rem 0.213333rem
				.item
					display flex
					justify-content space-between
					align-items center
					margin-bottom 0.16rem
					padding 0.3rem 0.586666rem 0.3rem 0.7rem
					background #F2F2F2
					border-radius 0.746666rem
					.name-box
						display flex
						justify-content flex-start
						align-items center
						.pic
							position relative
							top -0.106666rem
							width 0.933333rem
							height 0.933333rem
							border 1px solid #FFFFFF
							box-sizing border-box
							border-radius 50%
							img
								width 100%
								height 100%
								border-radius 50%
							p
								position absolute
								left -0.133333rem
								bottom -0.213333rem
								display flex
								justify-content center
								align-items center
								width 1.12rem
								height 0.4rem
								background #FFD469
								border-radius 0.133333rem
								font-size 0.32rem
								font-weight 500
								color #333333
						.name
							margin-left 0.32rem
							font-size 0.4rem
							font-weight 500
							color #333333
							span
								position relative
								left 0.106666rem
								top -0.24rem
								padding 0.053333rem 0.106666rem 0.026666rem
								font-size 0.32rem
								color #FFFFFF
								border-radius 6px
					.num
						font-size 0.346666rem
						font-weight 500
						color #333333
</style>

